/* eslint-disable */
import { errorFuc } from './httpError'
import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '../store/index'
import { message } from 'ant-design-vue'

const instance = axios.create()

// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let requests = []

// 请求次数
let requestCount = 0

function showLoading() {
  if (requestCount === 0) {
    store.commit('CHANGE_LOADING', true)
  }
  requestCount++
}

let timer
// (服务器响应后)尝试隐藏loading
function tryHideLoading() {
  requestCount--
  //采用setTimeout是为了解决一个请求结束后紧接着有另一请求发起导致loading闪烁的问题
  timer = setTimeout(() => {
    if (requestCount <= 0) {
      store.commit('CHANGE_LOADING', false)
      clearTimeout(timer)
    }
  })
}
// 请求拦截
instance.interceptors.request.use(
  (config) => {
    if(config.showLoading) {
      showLoading()
    }
    config.headers['accept-language'] = localStorage.getItem('language') || 'en'
    NProgress.start()
    return config
  },
  (error) => {
    requestCount = 0
    store.commit('CHANGE_LOADING', false)
    Promise.reject(error)
  }
)
// 响应拦截
instance.interceptors.response.use(
  (response) => {
    tryHideLoading()
    NProgress.done()
    return response.data
  },
  (error) => {
    const { response = {} } = error
    const { status = '', config } = response
    if (status === 401) {
      // 刷新token接口401过期抛出 且重置loading等待次数
      if (config.url.indexOf('refresh') !== -1) {
        requestCount = 0
        store.commit('CHANGE_LOADING', false)
        return Promise.reject(errorFuc(error))
      }

      if (!isRefreshing) {
        // 需要刷新时重置loading等待次数
        requestCount = 0
        isRefreshing = true

        return getRefreshToken()
          .then(() => {
            let access_token = sessionStorage.getItem('accessToken')
            // 已经刷新了token，将所有队列中的请求进行重试
            requests.forEach((cb) => cb(access_token))
            requests = []
            return instance(config)
          })
          .finally(() => {
            requestCount = 0
            store.commit('CHANGE_LOADING', false)
            isRefreshing = false
          })
      } else {
        // 正在刷新token，将返回一个未执行resolve的promise
        return new Promise((resolve) => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          requests.push((refreshToken) => {
            config.headers.Authorization = `${refreshToken}`
            resolve(instance(config))
          })
        })
      }
    } else {
      NProgress.done()
      // 遇到错误关闭loading 且重置loading等待次数
      requestCount = 0
      store.commit('CHANGE_LOADING', false)
      return Promise.reject(errorFuc(error))
    }
  }
)

function getRefreshToken() {
  // 登录时已经获取token储存在localStorage中
  return instance({
    url: '/boss/base/refresh',
    method: 'post',
    timeout: 20000,
  })
    .then((res) => {
      if (res && res.code === '000000') {
        return res.data
      } else {
        res.msg && message.error(res.msg)
        return Promise.reject(res.msg)
      }
    })
    .then((res) => {
      sessionStorage.refreshToken = 'Bearer ' + res.refreshToken
      sessionStorage.accessToken = 'Bearer ' + res.accessToken
      localStorage.setItem('accessToken', 'Bearer ' + res.accessToken)
      localStorage.setItem('refreshToken', 'Bearer ' + res.refreshToken)
      return Promise.resolve()
    })
}

export default instance
