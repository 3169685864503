<template>
  <a-layout-header
    :style="{background:!pageYOffset&&!$route.meta.transparence?'rgba(0,0,0,0)':'#1A529B','border-bottom':'1px solid #468DC4'}"
    class="header"
  >
    <div class="header-main">
      <img @click="$jumpTo('home-page')" class="logo" src="../assets/img/logo.png" />
      <div class="menu-nav ml-40">
        <ul>
          <li
            :class="{active:hash === item.id}"
            :key="item.path"
            @click="jump(item)"
            class="nav-btn"
            v-for="item in menus"
          >{{item.name}}</li>
        </ul>
      </div>
      <div style="flex:1"></div>
    </div>
  </a-layout-header>
</template>

<script>
  export default {
    data() {
      return {
        pageYOffset: false,
      }
    },
    computed: {
      menus() {
        return [
          {
            name: this.$t('navigation.home'),
            path: 'home-page',
            id: 'banner',
          },
          {
            name: this.$t('navigation.contactUs'),
            path: 'contact-us',
            id: 'contact_us',
          },
        ]
      },
      hash() {
        return location.hash
      },
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
    },
    beforeUnmount() {
      document.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleScroll() {
        //获取滚动时的高度
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
        if (scrollTop > 0) {
          this.pageYOffset = true
          //滚动大于0的时候要做的操作
        } else {
          this.pageYOffset = false
        }
      },

      jump(val) {
               window.scrollTo(0, 0)
 location.hash = `#${val.id}`
      },
      changeLang(val) {
        this.$i18n.locale = val
        this.$store.commit('SET_LANGUAGE', val)
        this.$store.dispatch('FETCH_DICTIONARY')
        window.scrollTo(0, 0)
        this.reload()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .header {
    position: fixed;
    padding: 0 75px;
    width: 100%;
    min-width: 1200px;
    background: #1a529b;
    z-index: 10;
    transition: all 0.25s;
    .header-main {
      display: flex;
      position: relative;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
    }
    li {
      display: inline-block;
    }
  }
  .logo {
    height: 36px;
    width: 192px;
    cursor: pointer;
  }
  .menu-nav {
    display: inline-block;
    ul {
      margin-bottom: 0;
    }
    ul li.active {
      color: #ffffff;
    }
  }
  .nav-btn {
    padding: 0px 15px;
    color: rgba($color: #ffffff, $alpha: 0.75);
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    cursor: pointer;
    &:hover {
      color: #ffffff;
    }
  }
  .menu-right {
    position: absolute;
    top: 0;
    right: 0;
  }
  .lang-switch {
    display: inline-block;
    padding: 3px 11px;
    min-width: 88px;
    color: rgba($color: #ffffff, $alpha: 0.75);
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border-radius: 14px;
    background: rgba($color: #ffffff, $alpha: 0.1);
    cursor: pointer;
    > img {
      width: 7px;
    }
    .active {
      color: #ffffff;
    }
  }

  .lang-dropdown {
    padding: 0 12px;
    font-size: 14px;
    background: rgba($color: #3d74af, $alpha: 0.9);
    border-radius: 14px;
    text-align: center;
  }
</style>

<style lang="scss">
  .lang-btn {
    display: block;
    padding: 2px 8px !important;
    color: #ffffff !important;
    font-size: 14px;
    line-height: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    text-align: center;
    cursor: pointer;
    & + & {
      border-top: 1px solid #597eb1;
    }
    &:hover {
      border-radius: 14px;
      background-color: rgba($color: #ffffff, $alpha: 0.15) !important;
    }
  }
</style>