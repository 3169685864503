/* eslint-disable */
import instance from "./instance";
import store from "../store/index";
import { message } from "ant-design-vue";

// 封装请求
export const opt = (options) => {
  if (!options.method) {
    options.method = "post";
  } else {
    options.params = options.data;
  }
  if (!options.showLoading) options.showLoading = true;

  return instance({
    ...options,
    timeout: 30000,
  }).then((res) => {
    if ((typeof res === "object") & (options.responseBody !== false)) {
      if (res && res.code === "000000") {
        return res.data;
      } else {
        res.msg && message.error(res.msg);
        return Promise.reject(res.msg);
      }
    } else {
      return res;
    }
  });
};

export { default as _base } from "./modules/base";
export { default as _system } from "./modules/system";
export { default as _home } from "./modules/home";
export { default as _user } from "./modules/user";
export { default as _fund } from "./modules/fund";
