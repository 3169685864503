import { createRouter, createWebHistory } from 'vue-router'
import index from '../views/index.vue'
const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/',
    component: index,
    children: [
      {
        path: '/',
        name: 'home-page',
        // meta: { title: '市场观察' },
        component: () => import(/* webpackChunkName: "home-page" */ '../views/home-page'), // 主页
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
