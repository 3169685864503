<template>
  <div>
    <router-view ></router-view>
    <!-- <caluse :isShow="auth!=='1'" /> -->
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { onUnmounted, onMounted, ref, computed, nextTick } from 'vue'
  import Caluse from '@/components/caluse'

  export default {
    components: {
      Caluse,
    },
    provide() {
      return {
      }
    },
    setup() {
      const store = useStore()
      const auth = computed(() => store.state.authorization)
      const authorization = localStorage.getItem('authorization') || '0'
      store.commit('SET_AUTH', authorization)

      onMounted(() => {
      })

      return {
        auth,
      }
    },
    methods: {
    
    },
  }
</script>

<style lang="scss" scoped>
</style>