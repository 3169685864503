import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: 'zh-CN', // 语言标识
  fallbackLocale: "en",
  messages: {
    "zh-CN": require("./zh-cn.js"), // 中文语言包
    "zh-HK": require("./zh-hk.js"), // 中文语言包
    en: require("./en.js"), // 英文语言包
  },
});
export default i18n;
