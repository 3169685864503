<template>
  <a-layout>
    <nav-header></nav-header>
    <a-layout-content class="body">
      <router-view></router-view>
    </a-layout-content>
    <home-footer />
  </a-layout>
</template>

<script>
  import NavHeader from '@/components/nav-header.vue'
  import HomeFooter from '../components/home-footer.vue'
  export default {
    components: {
      NavHeader,
      HomeFooter,
    },
    setup() {
      return {}
    },
  }
</script>

<style lang="scss" scoped>
  .body {
    margin-top: 66px;
    min-height: calc(100vh - 66px);
    background: #f9f9f9;
  }
</style>