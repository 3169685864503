import { createApp } from 'vue'
import App from './App.vue'
// import 'default-passive-events'
import installAntd from './plugins/components'
import mixin from '@/plugins/mixin'
import router from './router'
import '@/style/global.scss'
import '@/style/antd.less'
import store from './store'
import i18n from './locales/i18n'

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .mixin(mixin)

installAntd(app)

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  store.commit('SET_CURRENT_TAB', to.name)
  document.title = to.meta.title ? `杭州裕昇科技有限公司-${to.meta.title}` : '杭州裕昇科技有限公司'
  next()
})

app.mount('#site-app')
