import { opt } from '../index'

const baseUrl = '/cms/web'

export default {
  // 获取图片链接
  getFile: (data = {}) => opt({ url: `${baseUrl}/common/file`, data, method: 'get' }),
  // 手机区号
  getMobileCode: (data = {}) => opt({ url: `${baseUrl}/common/dial_code`, data, method: 'get' }),
}
