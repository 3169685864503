<template>
  <div class="footer">
    <!-- <div class="footer-content">
        <div class="footer-nav">
          <ul>
            <li
              :class="{ active: $store.state.currentTab === item.path }"
              :key="item.path"
              @click="jump(item.path)"
              class="nav-btn"
              v-for="item in menus"
            >{{ item.name }}</li>
          </ul>
        </div>
        <div class="contact-us">
          <p>{{ $t("navigation.contactUs2") }}</p>
          <p class="contact-content">
            <span class="name">{{$t("navigation.address_name")}}:</span>
            <span>{{ $t("navigation.address") }}</span>
            <br />
            <span class="name">{{$t("navigation.tel")}}:</span>
            <span>+852 2522 6288</span>
            <br />
            <span class="name">{{$t("navigation.email")}}:</span>
            <a class="color" href="mailto:cs@ascent-fp.com">info@glory-channels.com</a>
            <br />
           
            <span class="name">{{$t("navigation.public")}}:</span>
            <span>Glory Channel</span>
          </p>
        </div>
    </div>-->

    <p class="flr">
      <span>Copyright © 2021 裕昇 Coshine - 为数字化转型赋能，保留所有权利。</span>
      <!-- <span class="name">{{$t("navigation.record")}}:</span> -->
    </p>
    <div style="width:600px;margin:0 auto; ">
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002015918"
        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
        target="_blank"
      >
        <img src="~@/assets/img/portal.png" style="float:left;" />
        <p style="float:left;height:20px;line-height:20px;color:#939393;">浙公网安备 33011002015918号</p>
      </a>
      <a
        href="https://beian.miit.gov.cn"
        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;margin-left:15px;"
        target="_blank"
      >
        <p style="float:left;height:20px;line-height:20px;color:#939393;">浙ICP备2021025925号</p>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    computed: {
      menus() {
        return [
          {
            name: this.$t('navigation.home'),
            path: 'home-page',
            id: 'banner',
          },

          {
            name: this.$t('navigation.contactUs2'),
            path: 'contact-us',
            id: 'contact_us',
          },
          {
            name: this.$t('navigation.aboutUs'),
            path: 'about-us',
            id: 'about_us',
          },
        ]
      },
    },
    methods: {
      jump(val) {
        window.scrollTo(0, 0)

        location.hash = `#${val.id}`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .footer {
    padding: 40px 18px 40px 60px;
    background: #1a529b;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footer-content {
    display: inline-block;
  }
  .footer-nav {
    margin-bottom: 40px;
    ul li {
      display: inline-block;
    }
    ul li.active {
      color: #ffffff;
    }
  }

  .nav-btn {
    color: #ecf3ff;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    cursor: pointer;
    & + & {
      margin-left: 70px;
    }
    &:hover {
      color: #ffffff;
    }
  }
  .contact-us {
    font-size: 14px;
    color: #fff;
    > p:first-child {
      margin-bottom: 20px;
    }
    .contact-content {
      color: #d8d8d8;
      line-height: 20px;
      font-weight: 400;
      .name {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
        color: #7697c2;
        font-weight: 500;
        & + *:hover {
          color: #ecf3ff;
        }
      }
    }
  }
  .color {
    color: #d8d8d8;
  }

  .flr {
    color: #7697c2;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
</style>
