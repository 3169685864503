module.exports = {
  common: {
    more: '查看更多',
    month: {
      1: '一月',
      2: '二月',
      3: '三月',
      4: '四月',
      5: '五月',
      6: '六月',
      7: '七月',
      8: '八月',
      9: '九月',
      10: '十月',
      11: '十一月',
      12: '十二月',
    },
    subscribe: '订阅',
  },
  navigation: {
    home: '主页',
    fund: '基金',
    contactUs: '联系我们',
    contactUs2: '联系我们',
    aboutUs: '关于我们',
    privacy: '隐私政策',
    clause: '条款',
    address_name: '地址',
    address: '香港中环干诺道中34-37号华懋大厦19楼1902室',
    tel: '电话',
    email: '电邮',
    public: '公众号',
    record:'备案号'
  },
  caluse: {
    tips: '请浏览条款文件到底部，点击同意后查看网站内容',
    agree: '本人已阅读并同意以上条款',
  },
  home: {
    banner: {
      1: '专注于提供加密货币投资策略研究',
      2: '致力于挖掘市场中的低风险高回报机会',
    },
  },

  contact: {
    name: '称呼',
    lastName: '姓',
    firstName: '名',
    tel: '电话号码',
    email: '电子邮箱',
    channel: '了解杭州裕昇科技有限公司途径',
    code: '验证码',
    area: '国际区号',
    input: '请输入内容',
    select: '请选择',
    success: '订阅成功',
    statement: {
      1: '阁下提供的个人资料由杭州裕昇科技有限公司及其联营公司收集，并有机会用作往后由杭州裕昇科技有限公司的不定期推广或宣传产品及服务用途。',
      2: '你有权随时更改接收资讯的选择，如你不欲再收取我们发送的资讯，可电邮至{email}。',
      3: '本人已阅读并同意隐私资料及愿意日后收取由杭州裕昇科技有限公司发送的最新资讯。',
    },
  },

  aboutUs: {
    article2: {
      title: '团队',
      content: '后端技术团队方面亦涵盖了系统开发以及区块链项目等丰富经验，做到对投资和研究全方位掌控。',
    },
  },
}
