import { opt } from '../index'

const baseUrl = '/cms/web'

export default {
  // 订阅
  subscribe: (data = {}) => opt({ url: `${baseUrl}/act/subscribe`, data }),
  // 验证码
  captcha: (data = {}) => opt({ url: `${baseUrl}/act/captcha`, data, method: 'get', responseBody: false, responseType: 'blob' }),
}
