module.exports = {
  common: {
    more: '查看更多',
    month: {
      1: '一月',
      2: '二月',
      3: '三月',
      4: '四月',
      5: '五月',
      6: '六月',
      7: '七月',
      8: '八月',
      9: '九月',
      10: '十月',
      11: '十一月',
      12: '十二月',
    },
    subscribe: '訂閱',
  },
  navigation: {
    home: '主頁',
    fund: '基金',
    contactUs: '聯繫我們',
    contactUs2: '聯繫我們',
    aboutUs: '關於我們',
    privacy: '隱私政策',
    clause: '條款',
    address_name: '地址',
    address: '香港中环干诺道中34-37号华懋大厦19楼1902室',
    tel: '电话',
    email: '電郵',
    public: '公眾號',
    record: '備案號',
  },
  caluse: {
    tips: '請瀏覽條款文件到底部，點擊同意後查看網站內容',
    agree: '本人已閱讀並同意以上條款',
  },
  home: {
    banner: {
      1: '專注於提供加密貨幣投資策略研究',
      2: '致力於發掘市場中的低風險高回報機會',
    },
  },

  contact: {
    name: '稱呼',
    lastName: '姓',
    firstName: '名',
    tel: '電話號碼',
    email: '電子郵箱',
    channel: '了解杭州裕昇科技有限公司途徑 ',
    code: '驗證碼',
    input: '請輸入內容',
    select: '請選擇',
    area: '國際區號 ',
    success: '訂閱成功',
    statement: {
      1: '閣下提供的個人資料由杭州裕昇科技有限公司及其聯營公司收集，並有機會用作往後由杭州裕昇科技有限公司的不定期推廣或宣傳產品及服務用途。',
      2: '你有權隨時更改接收資訊的選擇，如你不欲再收取我們發送的資訊，可電郵至{email}。',
      3: '本人已閱讀並同意私隱資料及願意日後收取由杭州裕昇科技有限公司發送的最新資訊。',
    },
  },

  aboutUs: {
    article2: {
      title: '團隊',
      content: '後端技術團隊方面亦涵蓋了系統開發以及區塊鏈項目等豐富經驗，做到對投資和研究全方位掌控。',
    },
  },
}
