module.exports = {
  common: {
    more: 'More',
    month: {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    },
    subscribe: 'Subscribe',
  },
  navigation: {
    home: 'Home',
    fund: 'Fund',
    contactUs: 'Contact Us',
    contactUs2: 'Contact Us',
    aboutUs: 'About Us',
    privacy: 'Privacy Policy',
    clause: 'Terms & Conditions',
    address_name: '地址',
    address: '香港中环干诺道中34-37号华懋大厦19楼1902室',
    tel: '电话',
    email: 'Email',
    public: 'official account',
    record: 'Record No',
  },
  caluse: {
    tips: 'Please scroll down to the bottom to carefully read the Terms & Conditions. Press "I Agree." to browse the website',
    agree: 'I hereby confirm that I have read and accepted the Terms & Conditions',
  },
  home: {
    banner: {
      1: 'Specialize in providing cryptocurrency investment research',
      2: 'Strive to discover high-yield earning opportunities with minimal directional exposure',
    },
  },

  contact: {
    name: 'Title',
    lastName: 'Surname',
    firstName: 'Given Name',
    tel: 'Phone Number',
    email: 'Email Address',
    channel: 'How did you hear about us?',
    code: 'Verification Code',
    input: 'Please insert content',
    select: 'Please select',
    area: 'Country Code',
    success: 'success',
    statement: {
      1: 'The personal data provided above is collected by Coshine Tech and its affiliates for direct marketing purposes. Information sent by Coshine Tech includes latest market updates and products. ',
      2: 'If you change your mind at any time about wishing to receive the information from us, you can send an email message to us at {email}.',
      3: 'I have read and agreed with the Privacy Info and agree to receive the latest updates from Coshine Tech.',
    },
  },

  aboutUs: {
    article2: {
      title: 'Our Team',
      content: 'Our back-end development team is also experienced in software development and blockchain projects, which helps achieve a full control on our investment and research.',
    },
  },
}
