<template>
  <a-modal
    :bodyStyle="{
		padding:'0 0 30px 0',
		borderRadius: '10px',
	}"
    :closable="false"
    :destroyOnClose="true"
    :footer="null"
    :keyboard="false"
    :maskClosable="false"
    centered
    class="caluse"
    v-model:visible="visibleRef"
    width="800px"
  >
    <img class="head" src="@/assets/img/caluse_head.png" />
    <div @scroll="scrollEvent" class="content" ref="doc">
      <p>{{$t('clause.1')}}</p>
      <p>{{$t('clause.2')}}</p>
      <h6>{{$t('clause.3')}}</h6>
      <p>{{$t('clause.4')}}</p>
      <h6>{{$t('clause.5')}}</h6>
      <p>{{$t('clause.6')}}</p>
      <h6>{{$t('clause.7')}}</h6>
      <p>{{$t('clause.8')}}</p>
      <h6>{{$t('clause.9')}}</h6>
      <p>{{$t('clause.10')}}</p>
      <h6>{{$t('clause.11')}}</h6>
      <p>{{$t('clause.12')}}</p>
      <p>{{$t('clause.13')}}</p>
      <h6>{{$t('clause.14')}}</h6>
      <p>{{$t('clause.15')}}</p>
      <h6>{{$t('clause.16')}}</h6>
      <p>{{$t('clause.17')}}</p>
      <h6>{{$t('clause.18')}}</h6>
      <p>{{$t('clause.19')}}</p>
      <h6>{{$t('clause.20')}}</h6>
      <p>{{$t('clause.21')}}</p>
      <h6>{{$t('clause.22')}}</h6>
      <p>{{$t('clause.23')}}</p>
      <h6>{{$t('clause.24')}}</h6>
      <p>{{$t('clause.25')}}</p>
      <h6>{{$t('clause.26')}}</h6>
      <p>{{$t('clause.27')}}</p>
      <h6>{{$t('clause.28')}}</h6>
      <p>{{$t('clause.29')}}</p>
      <h6>{{$t('clause.30')}}</h6>
      <p>{{$t('clause.31')}}</p>
      <h6>{{$t('clause.32')}}</h6>
      <p>{{$t('clause.33')}}</p>
      <h6>{{$t('clause.34')}}</h6>
      <p>{{$t('clause.35')}}</p>
      <h6>{{$t('clause.36')}}</h6>
      <p>{{$t('clause.37')}}</p>
    </div>
    <p class="tips">{{$t('caluse.tips')}}</p>
    <div class="footer">
      <a-button :disabled="!canClick" @click="handleOk" class="btn">{{$t('caluse.agree')}}</a-button>
    </div>
  </a-modal>
</template>

<script>
  import { useStore } from 'vuex'
  import { ref, nextTick, watchEffect } from 'vue'

  export default {
    props: {
      isShow: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['cancel'],
    setup(props, ctx) {
      const store = useStore()
      const visibleRef = ref(false)
      const doc = ref(null)
      const canClick = ref(false)

      const handleOk = () => {
        canClick.value = false
        store.commit('SET_AUTH', '1')
        ctx.emit('cancel')
      }

      watchEffect(() => {
        visibleRef.value = !!props.isShow
        if (!props.isShow && doc.value) {
          doc.value.scrollTop = 0
        }
      })

      return {
        visibleRef,
        canClick,
        doc,
        handleOk,
      }
    },
    watch: {},
    methods: {
      scrollEvent(e) {
        if (
          e.srcElement.scrollTop + e.srcElement.clientHeight >=
          e.srcElement.scrollHeight
        ) {
          this.canClick = true
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .head {
    height: 150px;
    width: 100%;
  }
  .content {
    padding: 10px 40px 0 40px;
    height: 300px;
    color: #333333;
    font-size: 12px;
    line-height: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    overflow: auto;
    h5 {
      margin-bottom: 10px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    h6 {
      font-family: PingFangSC-Medium, PingFang SC;
      font-size: 12px;
      font-weight: 500;
    }
    p {
      margin-bottom: 15px;
    }
  }
  .tips {
    margin: 10px 0;
    padding: 0 40px;
    font-size: 14px;
    color: #1677ff;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 22px;
  }
  .footer {
    text-align: center;
    > .btn {
      padding: 13px 80px;
      height: 42px;
      min-width: 350px;
      line-height: 1;
      color: #fff;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      border: none;
      background: linear-gradient(180deg, #477edd 0%, #888afe 100%);
      border-radius: 4px;
      &[disabled] {
        color: rgba(0, 0, 0, 0.25);
        border: 1px solid #dddddd;
        background: #f5f5f5;
      }
    }
  }
</style>
<style lang="scss">
</style>